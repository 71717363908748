import React from "react";
import { Link, graphql } from "gatsby";

// Components
import Layout from "../components/layout.js";

const BlogIndex = ({ data }) => {
  const { edges } = data.allAsciidoc;

  return (
    <Layout title="Archive">
      <article>
        <h1>Blog archive</h1>
        <ul>
          {edges.map(({ node }) => {
            const {
              fields: { slug: path, tags },
              document: { title },
              revision: { date, isoDate },
            } = node;
            return (
              <li key={path}>
                <Link to={path}>{title}</Link>
                <footer className="footer">
                  <time dateTime={isoDate}>{date}</time>. Tagged{" "}
                  <ul className="inline-tags">
                    {tags.map((tag) => (
                      <li key={tag}>
                        <Link to={`/tags/${tag}`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </footer>
              </li>
            );
          })}
        </ul>
      </article>
    </Layout>
  );
};

export default BlogIndex;

export const query = graphql`
  query BlogQuery {
    allAsciidoc(limit: 2000, sort: { fields: [revision___date], order: DESC }) {
      totalCount
      edges {
        node {
          fields {
            slug
            tags
          }
          document {
            title
          }
          revision {
            isoDate: date(formatString: "YYYY-MM-DD")
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
